$(function () {
    document_ready();
    window_onload();
});

function document_ready() {
    ts = (new Date()).getTime();
    $("body").addClass("ready");
    var ie = detectIE();
    if (ie) {
        $("body").addClass("msie-"+ie);
        if (ie < 12) {
            $("body").addClass("msie");
        }
        else {
            $("body").addClass("msedge");
        }
    }

    responsive_init();
    init_event_handlers();
    bind_widgets();

    te = (new Date()).getTime();
    console.log("On-Ready Load Time: ", te - ts);
}

function window_onload() {
    ts = (new Date()).getTime();
    $("body").addClass("win-loaded").removeClass("win-not-loaded");

    responsive_update();

    te = (new Date()).getTime();
    console.log("Window Load Time: ", te - ts);
}

$(window).scroll(function () {
    if (typeof $("body").data("scrollval") === "undefined") $("body").data("scrollval", 0);

    scroll_animations();

    $("body").data("scrollval", $(window).scrollTop());
});

$(window).on("resize", function (e) {
    responsive_update(true, true);
    scroll_animations_resize();
});

function init_event_handlers() {
    icons_load();
    scroll_animations_init();
    fix_100vh_init();
    fix_touch_hovers();
    click_touch_init();
    goto_init();
    expand_it_init();
    header_init();
}

function bind_widgets(context) {
    img_to_bg(context);
}