
/*

 Click and touch events helpers

 */

function click_touch_init() {

    $(document).on("click.touchfocused", ".touchevents .touch-focused, .touch-focused-all", function (e) {
        var $o = $(this);
        if (!$o.hasClass("focused")) {
            if (!$o.hasClass("disable-prevent-link-clicks")) {
                e.preventDefault();
            }
            $(".touch-focused").not($o).not($o.closest(".touch-focused")).removeClass("focused");
            $o.addClass("focused");
        }
        else {
            if ($o.hasClass('touch-focused-toggle')) {
                $o.removeClass("focused");
            }
        }
    });

    $(document).on("click", ".touchevents .touch-focused a, .touch-focused-all a", function (e) {
        var $tf = $(this).closest(".touch-focused");
        if (!$tf.hasClass("focused") && !$tf.hasClass("disable-prevent-link-clicks")) {
            e.preventDefault();
        }
    });

    $(document).on("click touchstart", "*", function (e) {
        if (!$(e.target).closest(".touch-focused").length) {
            $(".touch-focused").removeClass("focused");
        }
    });

    $(document).on("click", ".no-click", function (e) {
        e.preventDefault();
    });

    $(document).on("click", ".no-propagation", function (e) {
        e.stopPropagation();
    });

}



/*

 Header functionality

 */

function header_init() {

    $(document).on("click", ".js-menu-switcher", function (e) {
        e.preventDefault();
        $("html").toggleClass("menu-overlay-active");
    });

    $(document).on("click", ".js-menu-hide", function (e) {
        e.preventDefault();
        $("html").removeClass("menu-overlay-active");
    });

    $(document).on("click", "*", function (e) {
        if (!$(e.target).closest(".menu-overlay, .js-menu-switcher").length) {
            $("html").removeClass("menu-overlay-active");
        }
    });

    $(document).on("touchmove", ".menu-overlay", function (e) {
        e.stopPropagation();
    });

    $(document).on("change", ".js-hcity select", function (e) {
        e.preventDefault();
        var $hcity = $(this).closest(".js-hcity");
        $hcity.find('.js-hcity-value').text($(this).val());
    });

}




/*

Responsive design helpers

 */

function responsive_init() {
    responsive_update();
}

function responsive_update(force, no_animation) {
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body").addClass('no-transition no-animation');
    }
    if (typeof force === "undefined") force = false;
    var ww = window.innerWidth;
    if ($("body").data("ww") != ww || force)
    {
        if (typeof Blazy !== 'undefined' && typeof Blazy.revalidate !== 'undefined') {
            Blazy.revalidate();
        }
        $("[data-place]").each(function(){
            var places = $(this).data("place");
            var breakpoints = Object.keys(places).map(function(value) {
                return parseInt(value);
            }).sort(function(a,b) {
                return a - b;
            }).reverse();
            for (i in breakpoints) {
                if (window.matchMedia("(min-width: "+breakpoints[i]+"px)").matches) {
                    if ($(places[breakpoints[i]]).length)  {
                        if (!$(this).next(places[breakpoints[i]]).length) {
                            $(this).attr("data-place-breakpoint", breakpoints[i]).insertBefore(places[breakpoints[i]]);
                        }
                    }
                    break;
                }
            }
        });

        $("body").data("ww", ww);
    }
    if (typeof no_animation !== 'undefined' && no_animation) {
        $("body")[0].offsetHeight;
        $("body").removeClass('no-transition no-animation');
    }
}


/*

Set Background Image depending on img content inside it

 */

function img_to_bg($o, context) {

    if (!$("body").hasClass("img-to-bg-inited-globally")) {

        $(window).on("resize", function(){
            delay_img_to_bg(function(){
                img_to_bg($(".img-to-bg-resp"));
            }, 100);
        });

        $("body").addClass("img-to-bg-inited-globally");
    }

    if (typeof $o === "undefined" || !$o) $o = $(".img-to-bg", context);
    $o.each(function () {
        var $imgtobg = $(this);
        var $img = $imgtobg.find("> img").first();
        if (!$img.length) {
            $img = $imgtobg.find("> picture img").first();
        }
        var src = $img[0].currentSrc;
        if (!src) {
            src = $img.attr('src');
        }
        if ($img.length && src) {
            $imgtobg.css("background-image", "url('" + src + "')");
            $imgtobg.addClass("img-to-bg--inited");
        }
    });
}

var delay_img_to_bg = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();


/*

Fix buggy 100vh behaviour on mobiles

 */

function fix_100vh_init() {
    if (!Modernizr.touchevents) return;

    fix_100vh();
    $("body").data("fix_100vh_ww", $(window).width());

    $(window).on("resize", function (e) {
        if ($("body").data("fix_100vh_ww") != $(window).width()) {
            fix_100vh();
        }
        $("body").data("fix_100vh_ww", $(window).width());
    });
}

function fix_100vh() {
    $(".js-fix-100vh").each(function(){
        $(this).css("height", window.innerHeight);
    });
    $(".js-fix-100vh-min").each(function(){
        $(this).css("min-height", window.innerHeight);
    });
    $(".js-fix-100vh-max").each(function(){
        $(this).css("max-height", window.innerHeight);
    });
}


/*

Scroll to needed objects

 */

function goto_init()
{
    $(document).on("click", ".js-goto", function (e) {
        var href = $(this).attr("href");
        var hash = href.split('#');
        if (hash.length > 1) {
            hash = '#'+hash.pop();
            var $o = $(hash);
            if ($o.length) {
                e.preventDefault();
                var $this = $(this);
                if ($this.closest(".js-goto-list").length)
                {
                    $this.closest(".js-goto-list").find("li").removeClass("active");
                    $this.closest("li").addClass("active");
                    tabs_update_pointer($this.closest(".js-tabs"));
                }
                if ($this.closest(".js-goto-hash-change").length)
                {
                    location_hash_update($this.attr("href"));
                }
                goto_object($o);
            }
        }
    });
}

function goto_object($o, speed, doffset)
{
    if (typeof speed === 'undefined') {
        speed = 500;
    }
    if ($o.length)
    {
        var offset = $o.offset().top;
        if (typeof doffset !== 'undefined') {
            offset += doffset;
        }
        var offset_el = $(".js-goto-offset").get(0);
        if (offset_el) {
            offset -= offset_el.offsetHeight;
        }
        if ($o.data("goto-offset-element")) {
            $($o.data("goto-offset-element")).each(function(){
                offset -= this.offsetHeight;
            });
        }
        goto_offset(offset, speed);
    }
}

function goto_offset(offset, speed)
{
    if (typeof speed === 'undefined') {
        speed = 500;
    }
    $("html, body").stop(true, true).animate({scrollTop: offset}, speed);
}

function activate_goto_link($obj) {
    $obj.addClass("active").siblings().removeClass("active")
        .end().parent("li").addClass("active").siblings().removeClass("active");
    $obj.trigger("click-tabs-fixed-center");
}


/*

Actions on Scroll

 */

function scroll_animations_init() {
    scroll_animations();
    window['headerOffset'] = parseInt($('.header-before').height(), 10);

    var controller = new ScrollMagic.Controller();

    $(".scroll-activate").each(function(){
        var scene = new ScrollMagic.Scene({
            duration: 0,    // the scene should last for a scroll distance of 100px
            offset: 50,
            reverse: ($(this).data('scroll-activate-reverse'))?$(this).data('scroll-activate-reverse'):false
        }).addTo(controller);
        scene.triggerHook("onEnter").triggerElement($(this).data("scroll-trigger-element") || $(this).closest(".scroll-activate-trigger").get(0)).setClassToggle(this, "scroll-active");
    });
}

function scroll_animations() {
    var st = $(window).scrollTop();
    header_scrolled();
}

function scroll_animations_resize() {
    window['headerOffset'] = parseInt($('.header-before').height(), 10);
    scroll_animations();
}

function header_scrolled() {
    var scrolled_condition = $(window).scrollTop() > window['headerOffset'];
    if (scrolled_condition) {
        if (!$(".header").hasClass("scrolled")) $(".header").addClass("scrolled");
    }
    else {
        if ($("header").hasClass("scrolled")) $(".header").removeClass("scrolled");
    }
}


function header_scroll_init() {
    window['headerLastScrollTop'] = 0;

    window['headerCalc'] = function() {
        window['headerHeight'] = $('.header').outerHeight();
        window['headerOffset'] = parseInt($('.header-before').height(), 10);
    }

    window['headerHasScrolled'] = function() {
        var st = $(this).scrollTop();

        // Make sure they scroll more than delta
        if(Math.abs(window['headerLastScrollTop'] - st) <= 5)
            return;

        // If they scrolled down and are past the navbar, add class .nav-up.
        // This is necessary so you never see what is "behind" the navbar.
        if (st > window['headerLastScrollTop'] && st > window['headerHeight'] + window['headerOffset']){
            // Scroll Down
            $('.header').removeClass('nav-down').addClass('nav-up');
        } else {
            // Scroll Up
            if(st + $(window).height() < $(document).height()) {
                $('.header').removeClass('nav-up').addClass('nav-down');
            }
        }

        window['headerLastScrollTop'] = st;
    }

    headerCalc();

    $(window).scroll(function(event){
        window['headerDidScroll'] = true;
    });

    $(window).resize(function(event){
        headerCalc();
    });

    setInterval(function() {
        if (window['headerDidScroll']) {
            headerHasScrolled();
            window['headerDidScroll'] = false;
        }
    }, 250);
}






/*

Expanded Blocks Functionality

 */

function expand_it_init()
{
    window['expand_it_window_width'] = $(window).width();
    $(window).on("resize", function () {
        if($(this).width() != window['expand_it_window_width']){
            expand_it_init_prepare(null, true);
            window['expand_it_window_width'] = $(this).width();
        }
    });

    $(document).on("click expand-it", ".expand-it", function(e){
        e.preventDefault();
        var $o = $($(this).data("expand-selector"));
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-wrapper").find(".expand-it-container").eq(0);
        }
        if (!$o.length)
        {
            $o = $(this).closest(".expand-it-container");
        }
        if (!$o.length)
        {
            $o = $($(this).attr("href"));
        }
        if (!$o.length) return;

        expand_it_trigger($(this), $o, e.type === "expand-it");
    });

    $(document).on("-webkit-transitionend transitionend", ".expand-it-container", function(e){
        var $o = $(e.target);
        if ($o.hasClass('expand-it-container')) {
            if ($o.hasClass("before-transition")) {
                expand_it_height_check($o);
            }
            expand_it_init_prepare($o.closest('.expand-it-container'), true);
            $(e.target).removeClass("before-transition");
            $(e.target).trigger("expandAfter");
        }
    });

    if (location.hash && location.hash.substr(0, 1).match(/\w/i))
    {
        if ($(location.hash).filter(".expand-it-wrapper").length)
        {
            var $o = $(location.hash);
            var $loc_link = $(".expand-it[href='"+location.hash+"']");
            if (!$loc_link.length)
            {
                $loc_link = $o.filter(".expand-it-wrapper").find(".expand-it");
            }
            if ($loc_link.not(".active").filter(":visible").length)
            {
                setTimeout(function(){
                    $loc_link.trigger("click");
                }, 300)
            }
        }
    }
}

function expand_it_height_check($o) {
    if ($o.hasClass("expand-it-container") && !$o.hasClass("expand-it-container-overflow-hidden"))
    {
        var height_default = 0;
        var current_height = parseInt($o.css("max-height"), 10);
        if ($o.find(".expand-it-container-height-default").length)
        {
            height_default = $o.find(".expand-it-container-height-default").height();
        }
        $o.toggleClass("overflow-visible", current_height > height_default);
    }
    if ($o.hasClass("expand-it-container") && $o.hasClass("expand-it-container-max-height-auto"))
    {
        var id = $o.attr("id");
        setTimeout(function(){
            removeCSSRule("rule-"+id);
        }, 300);
    }
}

function expand_it_trigger($handler, $o, soft) {

    var id = $o.attr("id");
    if (!id)
    {
        id = "id"+(new Date()).getTime() + $o.text().length;
        $o.attr("id", id);
    }
    height = $o.find(".expand-it-inner").eq(0).outerHeight(true);
    $o[0].offsetHeight;
    $o.addClass("no-transition-only-this");
    $o[0].offsetHeight;
    if (!$o.hasClass("expand-it-container-overflow-hidden"))
    {
        $o.removeClass("overflow-visible");
    }
    if ($o.hasClass("active"))
    {
        $o.removeClass("overflow-visible");
    }
    $o[0].offsetHeight;
    $o.removeClass("no-transition-only-this");
    if (!$o.hasClass("active") && !$("#rule-"+id).length) {
        addCSSRule("rule-"+id, "#"+id+".active { max-height: "+ height+"px; }");
    }
    $o[0].offsetHeight;

    if ($handler.attr("data-label")) {
        var label = $handler.html();
        $handler.html($handler.attr("data-label"));
        $handler.attr("data-label", label);
    }
    $handler.toggleClass("active");
    $(".expand-it.active[href='#"+$o.attr("id")+"']").not($handler).toggleClass("active");

    var $wrapper = $o.closest(".expand-it-wrapper");

    if (!soft)
    {
        $o.trigger("expandBefore");
        $o.addClass("before-transition").toggleClass("active").siblings(".expand-it-container").each(function(){
            $(".expand-it.active[href='#"+$handler.attr("id")+"']").trigger("expand-it");
            $handler.addClass("before-transition").removeClass("active");
        });
        var is_active = $o.hasClass("active");
        $wrapper.toggleClass("active", is_active);
        if ($wrapper.hasClass("expand-it-wrapper-collapse-siblings"))
        {
            $wrapper.siblings(".expand-it-wrapper").each(function(){
                var $this = $(this).find(".expand-it-container").first();
                if ($(this).find(".expand-it").length)
                {
                    $(this).find(".expand-it.active").trigger("expand-it");
                }
                else
                {
                    var $this_other = $(".expand-it.active[href='#"+$this.attr("id")+"']");
                    if ($this_other.length) {
                        $this_other.trigger("expand-it");
                    }
                    else {
                        var $this_other = $(this).find('.expand-it-pseudo')/*.filter(function() {
                            return $(this).parent($this).length === 0;
                        })*/;
                        if ($this_other.length) {
                            expand_it_trigger($this_other, $this, true);
                        }
                    }
                    if ($this_other.length) {
                        $this_other.removeClass("active");
                    }
                }
                $this.addClass("before-transition").removeClass("active");
                $(this).removeClass("active");
            });
            if ($wrapper.hasClass("active")) {
                setTimeout(function(){
                    var $sticky = $(".sticky, .fsticky");
                    var offset = 0;
                    if ($sticky.length) {
                        $sticky.each(function(){
                            offset = Math.max(offset, $sticky.outerHeight() + parseInt($sticky.css('top'), 10));
                        });
                    }
                    if ($wrapper.offset().top < $(window).scrollTop() + offset) {
                        goto_object($wrapper, 500, -1*offset - parseInt($wrapper.css('margin-top'), 10));
                    }
                }, 500);
            }
        }
        if (($o.hasClass("active") || $o.hasClass("expand-it-container--scroll-to-always")) && $handler.hasClass("expand-it-scroll-to"))
        {
            goto_object($o, 500, 'easeInOutQuad');
        }
        if ($handler.hasClass("expand-it-hash-change"))
        {
            if (is_active)
            {
                if ($handler.attr("href"))
                {
                    location_hash_update($handler.attr("href"));
                }
                else if ($wrapper.attr("id"))
                {
                    location_hash_update("#" + $wrapper.attr("id"));
                }
            }
            else
            {
                var $tabpane = $handler.closest(".tab-pane");
                if ($tabpane.length && $tabpane.attr("id"))
                {
                    location_hash_update("#"+$tabpane.attr("id"));
                }
                else
                {
                    location_hash_remove();
                }
            }
        }
        $o.trigger('expand-it-'+((is_active)?'open':'close'));
    }
}

function expand_it_init_prepare($c, force) {
    if (typeof $c === "undefined" || !$c) $c = $(".expand-it-container");
    if (!force) {
        $c = $c.not(".expand-it-container-prepared");
    }
    var rules = '';
    $c.each(function(){
        var $o = $(this);
        var id = $o.attr("id");
        if (!id)
        {
            id = "id"+(new Date()).getTime() + $o.text().length;
            $o.attr("id", id);
        }
        //height = $o.find(".expand-it-inner").outerHeight(true);
        height = $o.find(".expand-it-inner")[0].clientHeight;
        rules += " #"+id+".active { max-height: "+ height+"px; } ";
        $o.addClass("expand-it-container-prepared");
    });
    if (rules) {
        addCSSRule("rule-expand-it", rules);
    }
}